import Vue from 'vue'
import App from './App.vue'
import router from './config/router.js'
import store from './config/store.js'
import * as CONFIG from '@/config/init.js'
import axios from 'axios'
import * as VeeValidate from 'vee-validate';
Vue.use(VeeValidate, {
  validity: true
});
import {setInteractionMode, ValidationObserver, ValidationProvider} from "vee-validate";
import apiH from '@/mixins/apiHandlerMI.js'
import $ from 'jquery'

//CSS
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
// import 'bootstrap/dist/js/bootstrap.min.js'
import '@/css/main.min.css'

import PortalVue from 'portal-vue'
Vue.use(PortalVue)

var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo)

Vue.prototype.$axios = axios
Vue.prototype.$api_conf = CONFIG.api_conf;

Number.prototype.format = function(n) {
  var r = new RegExp('\\d(?=(\\d{3})+' + (n > 0 ? '\\.' : '$') + ')', 'g');
  return this.toFixed(Math.max(0, Math.floor(n))).replace(r, '$&,');
};

$('.count').each(function () {
  $(this).prop('counter', 0).animate({
    counter: $(this).text()
  }, {
    duration: 10000,
    easing: 'easeOutExpo',
    step: function (step) {
      $(this).text('' + step.format());
    }
  });
});

import Vuebar from 'vuebar';
Vue.use(Vuebar);

Vue.mixin(apiH);

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

// You can also pass in the default options
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})


Vue.config.productionTip = false

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
setInteractionMode('eager')

//mixins
import globalMI from '@/mixins/globalMI.js'
Vue.mixin(globalMI);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

