import * as CONFIG from '@/config/init.js'

export default {
  data: function () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      apiUrl: process.env.VUE_APP_API_URL + "/" + CONFIG.api_conf.apiDir
    }
  },
  computed: {
    selectedLangCode() {
      return this.$store.state.languages.find(item => parseInt(item.id) === parseInt(this.$store.state.language_id)).code
    }
  },
  methods: {
    priceRender(){
  
    },
    hasHttp(link){
      return link.includes("http") ? true : false
    },
    /////getPages//////
    pageReload() {
      this.$forceUpdate();
    },
    getMenuFull() {
      this.getMenuGroup('1')
      this.getMenuGroup('2')
      this.getMenuGroup('3')
    },
    getMenuGroup(id) {

      let _this = this
      this.get('helper/earthlings_menu/' + id, {
          params: {
            relations: 1,
            where: [['where', 'status', '1']],
          }
        },
      ).then((resp) => {
        // _this.$store.state.menu['menu_'+id] = resp.data.menu          
        if (parseInt(id) === 1) {
          _this.$store.state.menu.menu_1 = resp.data.menu
        } else if (parseInt(id) === 2) {
          _this.$store.state.menu.menu_2 = resp.data.menu
        } else if (parseInt(id) === 3) {
          _this.$store.state.menu.menu_3 = resp.data.menu
        }

      }).catch((err) => console.log(err));
    },
    getPage(id) {
      let _this = this;

      return new Promise((resolve) =>{

      
      _this.get('earthlings_page', {
          params: {
            relations: 1,
            where: [
              ['where', 'status', '1'],
              ['where', 'id', id]
            ],
            relations_params: {
              earthlings_page_block: {
                relations: 1,
                where: [
                  ['where', 'status', '1']
                ],
                relations_params: {
                  earthlings_slider: {
                    relations: 1,
                    where: [
                      ['where', 'status', '1']
                    ]
                  }
                }
              }
            }
          }
        },
      ).then((resp) => {        
        resolve(resp.data.earthlings_page)
      })

      })
    },

    // async handleFilesUpload() {
    //   for (const file of this.$refs.receipt.files) {
    //     await this.sendFileToApi(file)
    //   }

    //   this.$refs.receipt.value = ""
    // },
    //////language selection//////
    getSelectedLanguage() {
      let _this = this;
      this.get('dm_testimonial', {})
        .then((resp) => {
          _this.$store.state.reviews = resp.data.dm_testimonial;
          return resp.data.dm_testimonial;
        })
        .catch((e) => {
          console.log('error', e)
        })
      this.get('dm_partner', {})
        .then((resp) => {
          _this.$store.state.partners = resp.data.dm_partner;
          return resp.data.dm_partner;
        })
        .catch((e) => {
          console.log('error', e)
        })
    },
    GTtrack(category, action, label) {
      // console.log("track " , category, action, label);
      try {
        window.dataLayer.push({
          'event': 'event',
          'category': category,
          'action': action,
          'label': label
        });
      } catch (e) {
        console.log("not found dataLayer")
      }
    },
    setDataLayer(category, action, label) {
      this.GTtrack(category, action, label);
    },
    selectLangText() {
      let _this = this
      let languages = this.$store.state.languages
      const defaultLanguage = (languages ?? []).find((l) => l.id === _this.$api_conf.defaultLanguageId);
      const defaultLanguageCode = (defaultLanguage ? defaultLanguage.code : 'en');

      const availableLanguageCodes = (languages ?? []).map(l => l.code);
      const storedLanguageCode = localStorage.getItem('language');
      const validStoredLanguageCode = (availableLanguageCodes.includes(storedLanguageCode) ? storedLanguageCode : undefined);
      const selectedLanguageCode = validStoredLanguageCode ?? defaultLanguageCode;
      this.$store.state.selectedLanguage = selectedLanguageCode
      
      this.get(`lang/json_load/${selectedLanguageCode}`
        , {}
      ).then((response) => _this.$store.state.translation = response.data)
    },
    getSelectedLangFile(langCode){
        console.log('getSelectedLangFile', langCode)
        let _this = this
        _this.get('lang/json_load/' + langCode, {})
        .then((resp)=>{                       
          _this.$store.state.translation = resp.data
        }).catch(() => {        
            console.log('lang file error')
        }) 
    },
    async getCurrency(){
      if (this.$store.state.selecedCountry == null)
      {
        var current_language = {};
        
        await this.get('language/'+this.$store.state.language_id).then( (res)=>{
          current_language = res.data.language[0];
        })
        await this.get('country/'+current_language.default_country_id,{params: {relations:1}}).then( (res) => {
          this.$store.state.selectedCountry = res.data.country[0];
          localStorage.setItem('country', JSON.stringify(this.$store.state.selectedCountry));
        })
      }
    },
    getPrice(product){
      var ret = undefined;
      console.log(product)
      if (!product.earthlings_product_price)
        return false;

      product.earthlings_product_price.forEach(it=>{
        if (parseInt(it.country_id) == parseInt(this.$store.state.selectedCountry.id)){
          ret = it;
        }
      })
      if (ret == undefined){
        return false;
      }
      ret.price= parseFloat(ret.price);
      ret.delivery_price= parseFloat(ret.delivery_price);
      ret.fprice = this.formatPrice(parseFloat(ret.price), this.$store.state.selectedCountry.currency);
      ret.fdelivery_price = this.formatPrice(parseFloat(ret.delivery_price), this.$store.state.selectedCountry.currency);
      ret.ftotal = this.formatPrice(ret.price + ret.delivery_price, this.$store.state.selectedCountry.currency);
      return ret;
    },
    formatPrice(amount, currency){
      var ret = amount.toFixed(currency.decimal_place);
      return currency.symbol_left  + " " + ret + " " +currency.symbol_right;
    }
  }
}
