<template> <!--&& { 'onScroll': !topOfPage}-->
    <div class="header container-fluid p-0" :class="[showNavbar ? '' : 'hidden-navbar', topOfPage ? 'topOfPage': '']">
        <div class="d-flex align-items-center justify-content-between h-100">
            <div class=" text-left position-relative logo">
                <!-- LOGO -->
                <router-link class="" :to="{ name: 'Home', params:{lang: selectedLangCode}}">
                    <img alt="logo" class="img-fluid ml-2" src="@/assets/imgs/logo-linear.svg"/>
                </router-link>
            </div>

            <!--MENU-->
            <div class="dropdown menu-wrap d-flex w-100 h-100 justify-content-end align-items-center">
                <button
                    class="dropbtn header-menu dropdown-toggle d-lg-none d-block" id="menuShowBtn" data-toggle="dropdown" aria-expanded="false"
                >
                </button>
                <div class="dropdown-content dropdown-menu" aria-labelledby="menuShowBtn">
                    <div class="d-flex flex-column h-100 w-100">
                        <router-link v-for="menu in headerMenu" :key="menu.name" class="text-white text-uppercase menu__item d-flex justify-content-center align-items-center"
                            :to="menu.target_link" :class="menu.class"
                        >
                            <span :class="menu.class">{{ menu.name }}</span>
                        </router-link>
                        <div class="d-flex flex-row justify-content-around py-3">
                            <button class="text-white text-uppercase" :class="{'active-lang':lang.code === selectedLangCode}" :key="lang.code"
                                    v-for="lang in $store.state.languages" @click="selectLanguage(lang)">{{ lang.code.toUpperCase() }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="d-none d-lg-flex w-100 justify-content-evenly align-items-center h-100">
                    <router-link v-for="menu in headerMenu" :key="menu.name" class="d-flex justify-content-center align-items-center text-white menu-item text-uppercase w-100 h-100" 
                            :to="menu.target_link"
                        >
                        <span :class="menu.class">{{ menu.name }}</span>
                    </router-link>
                </div>
            </div>

            <!-- LANG SELECTOR -->
            <div class="header-center d-none d-lg-flex align-items-center flex-column justify-content-center">            
              <div class="header-center-language-selector d-flex">
                <button 
                    class="text-white text-uppercase" 
                    :class="{'active-lang':lang.code === selectedLangCode}" :key="lang.code"
                    v-for="lang in $store.state.languages" @click="selectLanguage(lang)">{{ lang.code.toUpperCase() }}
                </button>
              </div>
            </div>
        </div>
    </div>
</template>

<script>
import globalMI from "@/mixins/globalMI";

export default {
  name: "Header",
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      topOfPage: true,
      showNavbar: true,
      lastScrollPosition: 0
    }
  },
  beforeMount() {
    window.addEventListener('scroll', this.handleScroll)
  },
  computed: {
    headerMenu() {
      return this.$store.state.menu.menu_1 ? this.$store.state.menu.menu_1 : []
    }
  },
  mixins: [globalMI],
  methods: {
    selectLanguage(lang) {
      console.log('selectLanguage', lang)
      let _this = this
      localStorage.setItem('language', lang.code)
      this.$store.state.selecedCountry = null;
      localStorage.removeItem('country');
      this.$store.state.language_id = lang.id
      this.selectLangText();
      this.getSelectedLangFile(_this.selectedLangCode)
      // this.getSelectedLanguage();     
      this.getMenuFull()

      this.$router.push({name: 'Home', params: {lang: this.selectedLangCode}})

      if (this.$route.name == 'Home'){
        //this.$forceUpdate()
        location.reload()
      }

      //this is a custom page - go to the other lang
      // if (this.$route.meta.custom_page) {
      //   let customPageName = this.$route.name.split('_')
      //   this.$router.push({name: `${customPageName[0]}_${this.selectedLangCode}`})
      //   //location.reload() //get the page data again
      // } else {
      //   this.$router.push({name: 'Home', params: {lang: this.selectedLangCode}})
      // }
    },
    handleScroll(){
      /*if(window.pageYOffset>0){
        if(this.topOfPage){
          this.topOfPage = false
        }
      } else {
        if(!this.topOfPage){
          this.topOfPage = true
        }
      }*/
      
      if (window.pageYOffset < 0) {
          return
      }
      if(window.pageYOffset == 0){
        this.topOfPage = true;
      }else{
        this.topOfPage = false;
      }
      if (Math.abs(window.pageYOffset - this.lastScrollPosition) < 80) {
          return
      }
      
      this.showNavbar = window.pageYOffset < this.lastScrollPosition
      this.lastScrollPosition = window.pageYOffset
    }
  }
}
</script>

<style scoped>

</style>