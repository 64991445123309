import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/config/store.js'

Vue.use(VueRouter)

const routes = [
  {
    path: "/:lang",                    
    component: {
      render: (h) => h('router-view')                   
    },
    children: [
      {
        path: '',
        name: 'Home',
        components:{
          default: () => import('@/views/Home.vue')          
        }
        
      },
      {
        path: 'about-us',
        name: 'AboutUs_en',
      
        components:{
          default: () => import('@/views/AboutUs.vue')          
        },
        meta: {
          custom_page: true      
        }, 
      },
      {
        path: 'rolunk',
        name: 'AboutUs_hu',
      
        components:{
          default: () => import('@/views/AboutUs.vue')          
        },
        meta: {
          custom_page: true      
        }, 
      },
      {
        path: 'termekcsaladok',
        name: 'ProductFamilies_hu',
      
        components:{
          default: () => import('@/views/ProductFamilies.vue')          
        },
        meta: {
          custom_page: true      
        }, 
      },
      {
        path: 'productfamilies',
        name: 'ProductFamilies_en',
      
        components:{
          default: () => import('@/views/ProductFamilies.vue')          
        },
        meta: {
          custom_page: true      
        }, 
      },
      {
        path: 'termekcsalad/:family',
        name: 'Productfamily_hu',
      
        components:{
          default: () => import('@/views/ProductFamily.vue')          
        },
        meta: {
          custom_page: true      
        }, 
      },
      {
        path: 'productfamily/:family',
        name: 'Productfamily_en',
      
        components:{
          default: () => import('@/views/ProductFamily.vue')          
        },
        meta: {
          custom_page: true      
        }, 
      },
      {
        path: 'termek/:name',
        name: 'Product_hu',
      
        components:{
          default: () => import('@/views/Product.vue')          
        },
        meta: {
          custom_page: true      
        }, 
      },
      
      {
        path: 'product/:name',
        name: 'Product_en',
      
        components:{
          default: () => import('@/views/Product.vue')          
        },
        meta: {
          custom_page: true      
        }, 
      },
      {
        path: 'kosar',
        name: 'Cart_hu',
      
        components:{
          default: () => import('@/views/Cart.vue')          
        },
        meta: {
          custom_page: true      
        }, 
      },
      {
        path: 'cart',
        name: 'Cart_en',
      
        components:{
          default: () => import('@/views/Cart.vue')          
        },
        meta: {
          custom_page: true      
        }, 
      },
      // {
      //   path: 'termekek/:name',
      //   name: 'Product_hu',
      
      //   components:{
      //     default: () => import('@/views/Product.vue')          
      //   },
      //   meta: {
      //     custom_page: true      
      //   }, 
      // },
      {
        path: 'gravity_form',
        name: 'GravityForm',
      
        components:{
          default: () => import('@/views/GravityForm.vue')          
        },
        meta: {
          custom_page: true      
        }, 
      },
      {
        path: 'contact',
        name: 'Contact_en',
      
        components:{
          default: () => import('@/views/Contact.vue')          
        },
        meta: {
          custom_page: true      
        }, 
      },
      {
        path: 'kapcsolat',
        name: 'Contact_hu',
      
        components:{
          default: () => import('@/views/Contact.vue')          
        },
        meta: {
          custom_page: true      
        }, 
      }, 
      {
        path: 'contact-brossure',
        name: 'ContactBrossure_en',
      
        components:{
          default: () => import('@/views/ContactBrossure.vue')          
        },
        meta: {
          custom_page: true      
        }, 
      },
      {
        path: 'kapcsolat-brossura',
        name: 'ContactBrossure_hu',
      
        components:{
          default: () => import('@/views/ContactBrossure.vue')          
        },
        meta: {
          custom_page: true      
        }, 
      },    
      {
        path: 'privacy-policy',
        name: 'PrivacyPolicy_en',
      
        components:{
          default: () => import('@/views/PrivacyPolicy.vue')          
        },
        meta: {
          custom_page: true      
        }, 
      },
      {
        path: 'adatvedelmi-nyilatkozat',
        name: 'PrivacyPolicy_hu',
      
        components:{
          default: () => import('@/views/PrivacyPolicy.vue')          
        },
        meta: {
          custom_page: true      
        }, 
      },
      
      {
        path: 'payment_complete/:order_id',
        name: 'Paymentcomplete',
      
        components:{
          default: () => import('@/views/PaymentComplete.vue')          
        },
        meta: {
          custom_page: true      
        }, 
      },  
    ]
  },
  
  // {
  //   path: '/file-uploader',
  //   name: 'FileUploaderForm',
  
  //   components: { default: FileUploaderForm , header: Header}
  // },
  
  // {
  //   path: "*",
  //   redirect: '/'
  // }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,

  scrollBehavior: (to) => {    
    if (to.hash) {      
      Vue.nextTick(() => {
        setTimeout(() => {
          document.getElementById(to.hash.substring(1)).scrollIntoView();  
        }, 800);
        
      })
    }
    return {x: 0, y: 0}
  }
})




router.beforeEach((to, from, next) => {  
  console.log("beforeach", store.state.languages)
  //check the url
  const languageUrlSegment = to.path.split('/');      
  if (localStorage.getItem('country')){
    store.state.selectedCountry = JSON.parse(localStorage.getItem('country'));
  }
  languageUrlSegment.shift();      
  let language = languageUrlSegment[0];            
  console.log("lang beforeach", language )
  
  if (store.state.languages.findIndex(item => item.code === language) > -1){
    console.log('hello0', store.state.languages.find(item => item.code === language).id )
    //valid language url       
      // to.params.lang = language
      store.state.language_id = store.state.languages.find(item => item.code === language).id  
      localStorage.setItem('language', store.state.languages.find( item => parseInt(item.id) === parseInt(store.state.language_id)).code) 
      // console.log(language , 'store.state.language_id:', store.state.language_id)  
              
      next({
        params:{
          lang: language
        }
      })            
  } else {  
    //not exists the language
    if (localStorage.getItem('language')){
      console.log('hello1', localStorage.getItem('language'))
      language = localStorage.getItem('language')
    } else {      
      language = store.state.languages.find( item => parseInt(item.id) === parseInt(store.state.language_id)).code    
      console.log('hello2', language)
    }
    
    // console.log("next előtti lang", language)
    //go to the home page
    next({
      name: 'Home',
      params:{
        lang: language
      }
    })   
    if (to.hash) {      
      Vue.nextTick(() => {
        setTimeout(() => {
          document.getElementById(to.hash.substring(1)).scrollIntoView();  
        }, 800);
        
      })
    }   
  }         
})


export default router
