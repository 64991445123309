export const apiUrl = process.env.VUE_APP_API_URL;
export const api_conf = {
  branding: "Earthlings",
  apiDomain: apiUrl, // not tail per sign
  apiDir: "api",// not tail per sign
  apiName: "makersmarket", // not tail per sign
  uploadFolder: "uploads/makersmarket", // not tail per sign
  adminName: "Admin felület",
  websiteName: "Earthlings",
  invalidTokenCheck: true,
  invalidTokenRedirectPath: "/",
  invalidUserRedirect: "/",
  successLoginPath: "/",
  enabledUserTypes: [],
  needLanguage: true,
  defaultLanguageId: 2,
  defLanguages:[
    {
      id: 1,
      code: "hu",
      name: "Magyar"
    },
    {
      id: 2,
      code: "en",
      name: "English"
    }    
  ]
}

export const defaultLanguageId = 2;
export const needLanguage = true;
