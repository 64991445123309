<template>
  <div id="app" :class="routeName">
    <!-- <router-view name="header"/> -->
    <Header />
    <transition name="changePage" mode="out-in">
      <router-view/>
    </transition>
    <Footer v-if="!isFooter"/>
  </div>
</template>

<script>


import Footer from "@/components/Footer";
import Header from "@/components/Header";
import apiHandlerMI from "@/mixins/apiHandlerMI";
import * as CONFIG from '@/config/init.js'
export default {
  mixins: [apiHandlerMI],
  components: { Footer, Header},
  data(){
    return {
      routeName: '',
      isFooter: true
    }
  },
  watch:{
    '$route':{
      deep :true,
      handler(){
        this.routeName = this.$route.name;
        this.isFooter = this.routeCheck(this.routeName);
      }
    }
  },  
  created(){
    console.log('localstorage app created', CONFIG,localStorage.language);  

        if (!localStorage.language)
        {
          var userLang = navigator.language || navigator.userLanguage; 
          localStorage.setItem('language',userLang.substr(0,2))
          for (var i= 0; i< CONFIG.api_conf.defLanguages.length; i++)
          {
            if ( CONFIG.api_conf.defLanguages[i].code == localStorage.language)
            {
              this.$store.state.language_id = CONFIG.api_conf.defLanguages[i].id
            }
          }
        }
        //get the languages
        let _this = this                
        this.getSelectedLangFile(_this.selectedLangCode)    
        this.getMenuFull()              
  
  },
  methods: {
    routeCheck(name){
        return (name == 'Contact_'+this.selectedLangCode || name == 'ContactBrossure_'+this.selectedLangCode || name == 'GravityForm')
    }
  }
}
</script>
