<template>
    <div class="footer px-2 pt-5">
        <div class="d-flex justify-content-start">
            <img alt="logo" class="img-fluid" src="@/assets/imgs/logo-footer.svg"/>
        </div>
        <div class="d-flex footer__menu flex-column">
            <div class="d-flex flex-row w-100 justify-content-xl-between justify-content-start footer--border">
                <div class="footer__item" v-for="menu in footerMenu" :key="menu.name" :class="menu.sort_order === '1' ? 'footer__item--first':'d-none d-xl-block'">
                    <span v-if="menu.sort_order === '1'" class="text-white">{{menu.name}}</span>
                    <a v-else class="text-white" :href="menu.target_link">{{menu.name}}</a>
                </div>
                <div class="d-flex d-xl-none flex-column">
                    <a class="text-white" v-for="menu in footerMenu" :key="menu.name" :class="menu.sort_order === '1' ? 'd-none':''" :href="menu.target_link">{{menu.name}}</a>
                </div>
            </div>
            <div class="d-flex flex-row w-100 justify-content-xl-between justify-content-start footer--border">
                <div class="footer__item" v-for="menu in social" :key="menu.name" :class="menu.sort_order === '1' ? 'footer__item--first':'d-none d-xl-block'">
                    <span v-if="menu.sort_order === '1'" class="text-white">{{menu.name}}</span>
                    <a v-else class="text-white" :href="menu.target_link">{{menu.name}}</a>
                </div>
                <div class="footer__item d-none d-xl-block">
                    <a>&nbsp;</a>
                </div>
                <div class="d-flex d-xl-none flex-column">
                    <a class="text-white" v-for="menu in social" :key="menu.name" :class="menu.sort_order === '1' ? 'd-none':''" :href="menu.target_link">{{menu.name}}</a>
                </div>
            </div>
            <div class="d-flex flex-row w-100 justify-content-xl-between justify-content-start footer--border">
                <div class="footer__item footer__item--first">
                    <span>{{ $store.state.translation.el_contact }}</span>
                </div>
                <div class="footer__item d-none d-xl-block">
                    <a class="text-white" :href="'mailto:'+$store.state.translation.el_email_address" target="_blank">{{ $store.state.translation.el_email_address }}</a>
                </div>
                <div class="footer__item d-none d-xl-block">
                    <a class="text-white" :href="'tel:'+$store.state.translation.el_contact_phone">{{ $store.state.translation.el_contact_phone }}</a>
                </div>
                <div class="footer__item d-none d-xl-block">
                    <a>&nbsp;</a>
                </div>
                <div class="d-flex d-xl-none flex-column">
                    <a class="text-white" :href="'mailto:'+$store.state.translation.el_email_address" target="_blank">{{ $store.state.translation.el_email_address }}</a>
                    <a class="text-white" :href="'tel:'+$store.state.translation.el_contact_phone">{{ $store.state.translation.el_contact_phone }}</a>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-start">
            <span>Copyright © Designs by Earthlings</span>
        </div>
    </div>
    <!--<div class="footer container-fluid">
        <div class="row">
            <div class="col-12 text-start">
                <img alt="logo" class="img-fluid" src="@/assets/imgs/logo-footer.svg"/>
            </div>
            <div class="col-12 text-start">
                <h2 class="text-uppercase">MakersMarket.xyz LTD</h2>
                <p class="">London E2</p>
                <p class=""><a href="tel:+447908931855">+447908931855</a></p>
            </div>

            <div class="col-12 col-md-5 right-container d-flex flex-wrap ">

                <div class="right-container-right-column ">
                <h3 class="mb-4">{{ $store.state.translation.footer_follow_us }}</h3>
                <a class="d-flex text-blue text-capitalize flex-row mb-3" v-for="socialLink in socialLinkGroups"
                    :key="socialLink.name" :href="socialLink.target_link" target="_blank">
                    <div class="icon-container mr-3 text-center">
                    <img class="" :alt="socialLink.name" :src="require('@/assets/icons/'+socialLink.name+'.svg')"/>
                    </div>
                    {{ socialLink.name }}</a>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between lower-container align-items-center">
            <div class="lower-container-left">
                Copyright © 2022
            </div>
        </div>
        <div class="d-flex justify-content-around">
            <router-link class="text-white mr-5" :to="{name: 'PrivacyPolicy'}">{{ $store.state.translation.footer_terms }}</router-link>
            <router-link  class="text-white" :to="{name: 'PrivacyPolicy'}">{{ $store.state.translation.footer_privacy }}</router-link>
        </div>
    </div>-->
</template>

<script>

export default {
  name: "Footer",
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,      
    }
  },
  computed:{
    footerMenu(){
      return this.$store.state.menu.menu_2 ? this.$store.state.menu.menu_2 : []
    },
    social(){
      return this.$store.state.menu.menu_3 ? this.$store.state.menu.menu_3 : []
    }
  }
  
}
</script>

<style scoped>

</style>