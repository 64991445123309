import Vue from 'vue'
import Vuex from 'vuex'
// import axios from "axios";
import * as CONFIG from '@/config/init.js'

Vue.use(Vuex)

export default new Vuex.Store({

  state: {
    selectedCountry: null,
    selectedProduct: null,
    plugin: {
      color: 0,
      width: 100,
      height: 100,
      depth: 50,
      editMode: false,
      gridX: undefined,
      gridY: undefined,
      materialType: 0,
      thickness: 0
    },
    pluginFlow: {
      state: 'dimensions',
      width : 160,
      height : 160,
      horizontalDensity : 1,
      verticalDensity : 0.5,
      waveAmplitude : 0.6,
      brightnessCurve : 1,
      strokeSize : 0.6
    },
    uploadedData: {},
    token: false,
    userType: 'visitor',
    user: false,
    language_id: CONFIG.defaultLanguageId,
    languages: CONFIG.api_conf.defLanguages,    
    selectedLanguage: null,
    translation: {},
    reviews: [],
    partners: [],
    testimonial:[],
    menuGroups:[],
    pageUrls:{
      prepareForYourFile:'',
      whyLaserCutting:''
    },
    menu:{
      menu_1:[],
      menu_2:[],
      menu_3:[]
    }
    
  },
  mutations: {}
})
